body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
}
#root {
    height: 100%;
}
.mainContainer {
    height: 100%;
}
.routingContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
}
.routingContent {
    height: 100%;
    flex: 1;
    overflow: hidden;
}
#login-page { 
    height: 100%;
    background: url(images/login-background.jpg), url(images/login-background-lowres.jpg) no-repeat center center fixed; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#login-page .header { 
    color: white;
}
#login-page .panel { 
    width: 450px;
    background-color: white;
    border-radius: 4px;
}
#login-page .panel form { 
    padding: 25px 60px 45px 60px;
}
.AppInsight{
    display: inline;
}
#login-page .panel h2 { 
    width: 100%;
    text-align: center;
}
#login-page .panel form div div { 
    width: 100%;
}
#login-page .footer { 
    color: white;
}
#login-button {
    background: #09ACE3;
    padding: 0px 25px;
}
#login-button:hover {
    background: #09ACE3;
}

.breadCrumbs {
    padding: 30px 0 10px 20px;
}
.breadCrumbs div {
    padding: 0;
}
.breadCrumbs div div div {
    padding: 0 8px;
}
.breadCrumbs a {
    color: #5E6C84;
}
.page {
    height: 100%;
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.pageMap {
    height: 100%;
    padding: 0 0 0 20px;
    display: flex;
    flex-direction: column;
}

.pageOverviewMap {
    margin: 0 -20px;
    min-height: 300px;
    flex: 0;
    z-index: 499;
}

.pageMaximized {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.mapWithStationingSlider {
    flex: 1;
    display: flex;
    flex-direction: row;
}
.mapWithStationingSlider .stationingSlider {
    width: 50px;
    height: 99%;
    display: flex;
    flex-direction: column;
}

#map-element-container, #map-element-container-rtl {
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.4);
    color: black !important;
    display: flex;
    height: fit-content;
    width: fit-content;
    margin: 2px 2px 2px 2px;
    position: relative;
    z-index: 1100;
}

#map-element-container-rtl {
    float: right;
}

#map-element, #map-element-left, #map-element-left-right, #map-element-right, #map-element-top, #map-element-bottom, #map-element-single {
    background: white;
    border-radius: 0;
    color: black !important;
    min-height: 36px;
    height: fit-content;
}

#map-element-left {
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    border-right: 1px solid #ccc;
}

#map-element-left-right {
    border-right: 1px solid #ccc;
}

#map-element-right {
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
}

#map-element-top {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom: 1px solid #ccc;
}

#map-element-bottom {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

#map-element-single {
    border-radius: 2px;
}

#map-element:hover, #map-element-left:hover, #map-element-right:hover, #map-element-top:hover, #map-element-bottom:hover, #map-element-single:hover {
    background: rgb(244, 244, 244);
}

.mapStacked {
    flex: 1;
    position: relative;
}

.mapStackedChild {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 300;
}

.map {
    z-index: 299;
}

.track-vertical {
    top: 2px;
    bottom: 2px;
    right: 2px;
    border-radius: 3px;
    background: rgba(9, 30, 66, 0.08);
}

.track-vertical .thumb-vertical {
    position: relative;
    display: block;
    width: 100%;
    cursor: pointer;
    border-radius: inherit;
    background: rgba(9, 30, 66, 0.16);
}

.mapWithStationingSlider .map{
    flex: 1;
    height: 99%;
}

.stationingSlider .slider {
    flex: 1;
    background:rgba(9,30,66,0.04);
    margin-top: 10px;
}

.sideBar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: 200px;
    margin-left: 2px;
}

.diagramshorizontal {
    flex: 1;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.diagramshorizontal .diagram {
    flex: 1;
}

.diagramsvertical {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.tableContainer {
}
.tableContainer table a {
    color: #09ACE3 !important;
    cursor: pointer;
}

.homeContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -5px;
}
.homeContainer #previewMaps,
.homeContainer #tables {
    margin-left: 5px;
}

.homeProjectPreviewContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -5px 10px -5px -5px;
}

.projectPreviewContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: -5px;
}

.projectPreview {
    min-width: 225px;
    max-width: 225px;
    border: 1px solid #DFE1E6;
    border-radius: 3px;
    color: #5E6C84;
    margin: 5px;
}

.quicksearch>div>div{
    border-color: #5E6C84
}

/* chrome specific */
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 30px #FAFBFC inset;
}
input:-webkit-autofill:hover {
    -webkit-box-shadow: 0 0 0px 30px #EBECF0 inset;
}

#global_navigation {
    height: 100%;
    position: relative;
}

#offline_navigation {
    background-color: red;
    height: 20px;
    width: 100%;
    text-align: center;
    font-weight: bold;
    position: absolute;
}

#outdated-alert {
        background-color: red;
}

.leaflet-right .leaflet-control-scale-line {
    font-size: 300%;
    margin-top: 20px;
    margin-right: 20px;
}

.hiddenInspection .leaflet-container {
    background-color: transparent;
}