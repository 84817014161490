.leaflet-grid-label {
    /* todo */
}

.gridlabel-horiz,
.gridlabel-vert {
    /* todo */
}

.gridlabel-horiz-highRes,
.gridlabel-vert-highRes {
    font-size: 200%;
}